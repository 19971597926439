
export default
	props:
		copy: String
		scrollProgress: Number
		copyAndCtaRevealTime: Number
		buttons: Array

	computed:
		visible: -> @scrollProgress >= @copyAndCtaRevealTime

