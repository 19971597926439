
export default
	props:
		entries: Array
		scrollProgress: Number

	computed:
		computedEntries: -> @entries.map (entry) => {
			...entry
			visible: @isEntryVisible(@scrollProgress, entry)
		}

	methods:
		isEntryVisible: (progress, entry) ->
			return unless progress? and entry.startPosition? and entry.endPosition?
			return entry.startPosition <= progress <= entry.endPosition

